import React from 'react';
import { Link } from 'gatsby';

import { ThemeProvider } from 'styled-components';

import Layout from '../../layouts/blogLayoutCarpetCleaning';

import { Wrapper } from '../../styles/pages/blog/professional-carpet-cleaning-information';
import theme from '../../styles/theme';

const ProfessionalCarpetCleaningInformationBlogPost = () => {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Wrapper>
          <h1>Information About Professional Carpet Cleaning Services</h1>
          <p style={{ fontSize: '90%', color: '#777' }}>April 4th, 2019</p>
          <p>
            When considering professional carpet cleaning services, you may have
            a lot of questions. This is especially true if you haven’t had your
            carpets cleaned in some time.
          </p>
          <p>
            Depending on how specific your questions are, the easiest way to get
            answers might be to simply <Link to="/contact">contact us</Link>.
            However, on this page we discuss a few of the most common questions
            faced when considering carpet cleaning services.
          </p>
          <h2>How do I know if my carpets need to be cleaned?</h2>
          <p>
            The simplest way to know if you need to have your carpets cleaned is
            to check if they look dirty or have odors. Carpets are designed to
            conceal dirt. This means that when carpets look dirty, they are
            actually quite filthy.
          </p>
          <p>
            Having your carpets cleaned regularly serves a number of purposes.
            Not only will it keep your carpets looking clean and smelling good,
            but there are other benefits as well.
          </p>
          <ul>
            <li>Regular carpet cleaning will help your carpets last longer.</li>
            <li>Carpet cleaning can help remove pet hair.</li>
            <li>Carpet cleaning can help improve some allergies.</li>
            <li>Carpet cleaning can help remove some pests, such as fleas.</li>
            <li>And more.</li>
          </ul>
          <h2>What method should be used for carpet cleaning?</h2>
          <p>
            There are a number of acceptable methods for carpet cleaning. The
            method we recommend most is hot water extraction (steam cleaning).
          </p>
          <p>
            This method has been the standard approach to professional carpet
            cleaning for many years. In certain special situations, we have used
            other methods of cleaning such as low-moisture cleaning. Still, hot
            water extraction (steam cleaning) remains our recommendation.
          </p>
          <h2>How often should carpets be cleaned?</h2>
          <p>
            The recommended frequency of carpet cleaning varies by situation.
            Some homes have more traffic and spills than others. Some homes have
            pets and young children. These factors, among others, impact how
            often you should have your carpets cleaned.
          </p>
          <p>
            The general rule of thumb is to have your carpets cleaned every
            12-18 months. Depending on factors, such as those mentioned above,
            you may want to have your carpets cleaned more or less often than
            this guideline, though.
          </p>
          <h2>Can stains be removed from the carpet?</h2>
          <p>
            Stains can often be removed. Some stains are more difficult to
            remove than others, though. It’s also extremely important deal with
            stains as quickly as possible. The longer a stain remains on the
            carpet, the less likely it can be removed.
          </p>
          <h2>How are pet problems handled?</h2>
          <p>
            Pet problems can be divided into two categories: stains and odors.
          </p>
          <p>
            Pet stains are typically dealt with by cleaning the area and using
            special stain removers. Pet urine stains are typically more
            troublesome than other pet stains. However, as long as the stain is
            treated quickly, it is often removable.
          </p>
          <p>
            Pet odors and typically handled with a topical pet deodorizer. These
            treatments are extremely effective and usually completely remove or
            greatly reduce odors caused by pet urine. In the event that the
            topical spray isn’t enough to deal with the pet odor, pet flushes
            can be used.
          </p>
          <p>
            Pet flushes involve saturating the affected areas with a mixture of
            pet deodorizers and hot water followed by extracting the areas to
            remove the urine and deodorize the spots.
          </p>
          <h2>Does carpet cleaning reduce allergens?</h2>
          <p>
            It definitely does. Many substances can be in the carpet that can
            trigger allergies. By thoroughly cleaning your carpets, these
            substances can be removed from your home.
          </p>
          <p>
            Allergens can be in many places other than the carpet, though. If
            you’re struggling with allergies, more steps may be required aside
            from only carpet cleaning.
          </p>
        </Wrapper>
      </Layout>
    </ThemeProvider>
  );
};

export default ProfessionalCarpetCleaningInformationBlogPost;
